import React from "react"
import styled from "styled-components"

const TestimonialSection = () => (
  <SiteTestimonialSection>
    <div className="testimonial-wrapper">
      <h2>SATISFIED CUSTOMERS</h2>
      <p className="quote">
      “I have the tools to answer customer calls, quote and invoice my customers. 
      Where I struggled was giving clear direction to my team about each job. 
      How do I assign the job to the right person, track the job, collect and store 
      critical information about the job and then know when a job is closed? How can 
      I make sure the jobs are done to our standards and how can I see my overall 
      workflow by employee and for my whole company? I’ll tell you how I used to 
      do it – texts, calls and paperwork. Now I do all my work tickets in MyServicePak 
      and my life is a whole lot less complicated."
      </p>
      <p className="author">- Elisha French Texas Plumbing Solutions</p>
    </div>
  </SiteTestimonialSection>
)

export default TestimonialSection

const SiteTestimonialSection = styled.div`
  .testimonial-wrapper {
    max-width: ${props => props.theme.section.width};
    margin: auto;
    padding: 65px 65px 45px;
    text-align: center;

    .quote {
      font-style: italic;
      margin: 40px 0 30px;
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      padding: 30px;

      h2 {
        font-size: ${props => props.theme.font.size.large};
      }

      .quote {
        margin: 30px 0 20px;
      }
    }
  }
`
