import React from "react"
import styled from "styled-components"

const Icon = ({ src, copy, white = false }) => {
  const SiteIcon = white ? WhiteIcon : DefaultIcon
  return (
    <SiteIcon>
      <div className="icon-wrapper">
        <img className="icon" {...{ src, alt: copy }} />
        <p className="copy">{copy}</p>
      </div>
    </SiteIcon>
  )
}

export default Icon

const DefaultIcon = styled.div`
  .icon-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 16px 10px;
    width: ${props => props.theme.icon.large.width};

    .icon {
      height: ${props => props.theme.icon.large.imgsize};
      margin-bottom: 8px;
      width: ${props => props.theme.icon.large.imgsize};
    }

    .copy {
      color: ${props => props.theme.color.charcoal};
      line-height: 1.1;
      text-align: center;
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      height: ${props => props.theme.icon.small.height};
      margin: 16px 4px;
      width: ${props => props.theme.icon.small.width};

      .icon {
        height: ${props => props.theme.icon.small.imgsize};
        width: ${props => props.theme.icon.small.imgsize};
      }

      .copy {
        color: ${props => props.theme.color.charcoal};
        font-size: ${props => props.theme.font.size.small};
        line-height: 1.1;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      width: 160px;
    }

    @media (max-width: ${props => props.theme.breakpoint.mobile}) {
      width: 120px;
    }
  }
`

const WhiteIcon = styled(DefaultIcon)`
  .icon-wrapper {
    margin: 20px;
    width: 160px;

    .icon {
      height: ${props => props.theme.icon.large.imgsizeWhite};
      width: ${props => props.theme.icon.large.imgsizeWhite};
    }

    .copy {
      color: white;
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      margin: 20px 30px;
      width: 120px;

      .icon {
        height: ${props => props.theme.icon.small.imgsizeWhite};
        width: ${props => props.theme.icon.small.imgsizeWhite};
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      margin: 20px 10px;
      width: 110px;
    }

    @media (max-width: ${props => props.theme.breakpoint.mobile}) {
      margin: 20px 2px;
      width: 96px;
    }
  }
`
