import React from "react"
import styled from "styled-components"
import Icon from "../components/icon"

import runYourBusinessIcon from "../images/icons/runyourbusiness.svg"
import trackWorkIcon from "../images/icons/trackwork.svg"
import startAndFinishTimesIcon from "../images/icons/startandfinishtimes.svg"
import realTimeIcon from "../images/icons/realtime.svg"
import customChecklistIcon from "../images/icons/customchecklist.svg"
import confirmationPhotoIcon from "../images/icons/confirmationphoto.svg"
import uploadIcon from "../images/icons/upload.svg"
import complimentsIcon from "../images/icons/compliments.svg"

const IconSection = () => (
  <SiteIconSection>
    <div className="icon__section-wrapper">
      <h3>MYSERVICEPAK – REMOTE WORKFLOW MANAGEMENT</h3>
      <h6 className="subtitle">
        A unique, powerful tool engineered to give you the visibility you need
      </h6>
      <div className="icons-wrapper">
        <Icon
          src={runYourBusinessIcon}
          copy="Run your business from anywhere"
        />
        <Icon
          src={trackWorkIcon}
          copy="Quick and easy to assign and track work"
        />
        <Icon
          src={startAndFinishTimesIcon}
          copy="Set expected start and finish times"
        />
        <Icon src={realTimeIcon} copy="See all jobs in real time" />
        <Icon
          src={customChecklistIcon}
          copy="Add custom checklists to work tickets"
        />
        <Icon src={confirmationPhotoIcon} copy="Confirm photo for every job" />
        <Icon src={uploadIcon} copy="Easily upload customer lists" />
        <Icon
          src={complimentsIcon}
          copy="Compliments existing proposal and accounting software"
        />
      </div>
    </div>
  </SiteIconSection>
)

export default IconSection

const SiteIconSection = styled.div`
  background: ${props => props.theme.color.lightgrey};

  .icon__section-wrapper {
    margin: auto;
    max-width: ${props => props.theme.section.width};
    padding: 50px 40px 30px;
    text-align: center;

    .subtitle {
      color: ${props => props.theme.color.charcoal};
      font-size: ${props => props.theme.font.size.medium};
      margin: 14px 0;
    }

    .icons-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      padding: 30px 20px 40px;
    }
  }
`
